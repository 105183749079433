import './App.css';
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import CookieBanner from './Components/Banner';

(function (t, e) {
  "use strict";
  var widget_url = "";
  window.lpTag = window.lpTag || {};
  window.destination = "test";
  window.chatfullscreen = false;
  var r = function (t) {
      try {
          var r = e.head || e.getElementsByTagName("head")[0],
              a = e.createElement("script");
          a.setAttribute("type", "text/javascript"); a.setAttribute("src", t); r.appendChild(a);
      } catch (t) {}
  };

  t.NDSId = "local";
	 r("https://web-api:5000/js/chat.js");
})(window, document);
console.log(window.domain);

function Home() {

  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
	  setModalOpen(!isModalOpen);
	};

  return (
	<div>
		<div className="oz-body-wrap">
			<header className="default-header">
				<div className="container-fluid">
					<div className="header-wrap">
						<div className="header-top d-flex justify-content-between align-items-center">
							<div className="logo">
								<a href="index.html"><img src="img/segurosai.svg" alt="Seguros AI" style={{ width: "150px", marginRight: "30px" }}/><img src="img/adelanta.png" alt="Adelanta" style={{ width: "100px" }}/></a>
							</div>
							<div className="main-menubar d-flex align-items-center">
								<nav className="hide">
									<Link to="/">Inicio</Link>
									<Link to="/privacy">Aviso de Privacidad</Link>
								</nav>
								<div className="menu-bar"><span className="lnr lnr-menu"></span></div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<section className="banner-area relative">
				<div className="container">
					<div className="row fullscreen align-items-center justify-content-center">
						<div className="banner-left col-lg-6">
							<img className="d-flex mx-auto img-fluid" src="img/family.jpg" alt="" />
						</div>
						<div className="col-lg-6">
							<div className="story-content">
								<h6 className="text-uppercase">Tu Asistente Virtual de Seguros</h6>
								<h1>Seguridad y <span className="sp-1">Tranquilidad</span><br />
								para tu <span className="sp-2">Futuro</span></h1>
								<a href="/" className="genric-btn primary circle">Comienza Ahora &nbsp; <span className="lnr lnr-arrow-right"></span></a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="video-area pt-40 pb-40">
				<div className="overlay overlay-bg"></div>
				<div className="container">
					<div className="video-content">
						<a href="https://www.youtube.com/watch?v=dxsNjvOfTKg" className="play-btn"  target="_blank" rel="noopener noreferrer"><img src="img/play-btn.png" alt="" /></a>
						<div className="video-desc">
							<h3 className="h2 text-white text-uppercase">La Importancia de Estar Asegurado</h3>
							<h4 className="text-white">Video aparecerá en ventana emergente</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="about-area pt-100 pb-100">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="story-content">
								<h2>Información <br />
								Acerca de <span>Seguros Sadek-Parés</span></h2>
								<p className="mt-30">
									En Seguros Sadek-Parés, nos comprometemos a brindar las mejores opciones de seguros para proteger lo que más valoras. Desde seguros de auto hasta seguros de vida, nuestro asistente virtual te ayudará a encontrar la cobertura perfecta para tus necesidades.
								</p>
								<a href="/" className="genric-btn primary-border circle">Ver Más &nbsp; <span className="lnr lnr-arrow-right"></span></a>
							</div>
						</div>
						<div className="col-lg-6">
							<img className="img-fluid d-flex mx-auto" src="img/extra.jpg" alt="" />
						</div>
					</div>
				</div>
			</section>
			<section className="feature-area pt-100 pb-100 relative">
		<div className="overlay overlay-bg"></div>
		<div className="container">
			<div className="row">
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-heart"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Protección Familiar</h2>
							<p>
								Un seguro de vida garantiza la estabilidad financiera de tus seres queridos en caso de tu ausencia.
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-thumbs-up"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Tranquilidad</h2>
							<p>
								La tranquilidad de saber que tu familia estará protegida económicamente en todo momento.
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-graduation-hat"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Futuro Seguro</h2>
							<p>
								Asegura el futuro de tus hijos, cubriendo gastos educativos y otras necesidades.
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-briefcase"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Planificación Financiera</h2>
							<p>
								Facilita la planificación financiera a largo plazo, asegurando un respaldo económico.
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-diamond"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Cobertura Integral</h2>
							<p>
								Ofrecemos coberturas adaptadas a tus necesidades para una protección completa.
							</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
					<div className="single-feature">
						<div className="icon">
							<span className="lnr lnr-heart-pulse"></span>
						</div>
						<div className="desc">
							<h2 className="text-uppercase">Apoyo en Momentos Difíciles</h2>
							<p>
								Proporciona un respaldo económico para enfrentar situaciones imprevistas con mayor tranquilidad.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
			{/* <section className="feature-area pt-100 pb-100 relative">
				<div className="overlay overlay-bg"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-car"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Auto</h2>
									<p>
										Protege tu vehículo con nuestras coberturas completas y personalizadas.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-phone"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Hogar</h2>
									<p>
										Mantén tu hogar y tus pertenencias seguras contra cualquier imprevisto.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-graduation-hat"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Vida</h2>
									<p>
										Asegura el bienestar de tus seres queridos con nuestras opciones de seguros de vida.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-heart"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Salud</h2>
									<p>
										Cuida de tu salud con nuestras coberturas médicas adaptadas a tus necesidades.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-briefcase"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Viaje</h2>
									<p>
										Disfruta de tus viajes con la tranquilidad de estar protegido ante cualquier eventualidad.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
							<div className="single-feature">
								<div className="icon">
									<span className="lnr lnr-lock"></span>
								</div>
								<div className="desc">
									<h2 className="text-uppercase">Seguro de Negocios</h2>
									<p>
										Protege tu empresa con nuestras soluciones de seguros adaptadas a tu sector.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<section className="faq-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="counter-left col-lg-3 col-md-3">
							<div className="single-facts">
								<h2 className="counter">85%</h2>
								<p>Clientes satisfechos</p>
							</div>
							<div className="single-facts">
								<h2 className="counter">98%</h2>
								<p>Renovación de pólizas</p>
							</div>
							<div className="single-facts">
								<h2 className="counter">24/7</h2>
								<p>Asistencia en reclamaciones</p>
							</div>
							<div className="single-facts">
								<h2 className="counter">95%</h2>
								<p>Índice de reclamaciones resueltas</p>
							</div>
						</div>
						<div className="faq-content col-lg-9 col-md-9">
							<div className="single-faq">
								<h2 className="text-uppercase">
									¿Qué tipos de seguros ofrecen?
								</h2>
								<p>
									Ofrecemos una amplia gama de seguros, incluyendo vida, salud, automóvil y hogar, diseñados para cubrir todas tus necesidades.
								</p>
							</div>
							<div className="single-faq">
								<h2 className="text-uppercase">
									¿Cómo puedo cotizar un seguro?
								</h2>
								<p>
									Utiliza nuestro asistente virtual para obtener una cotización instantánea o contacta con uno de nuestros agentes para una consulta personalizada.
								</p>
							</div>
							<div className="single-faq">
								<h2 className="text-uppercase">
									¿Por qué elegir Seguros Sadek-Parés?
								</h2>
								<p>
									Nos distinguimos por nuestra atención al cliente excepcional, productos innovadores y compromiso con la satisfacción del cliente.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="contact-area pt-100 pb-100 overlay-bg">
				{/* <div className="overlay overlay-bg"></div> */}
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-7">
							<div className="contact-content text-center">
								<h2 className="text-uppercase">Contáctanos</h2>
								<p>
									Estamos aquí para ayudarte. Completa el siguiente formulario y uno de nuestros agentes se pondrá en contacto contigo lo antes posible.
								</p>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-9">
							<form className="contact-form">
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<input type="text" name="name" className="form-control" placeholder="Nombre" required />
										</div>
										<div className="form-group">
											<input type="email" name="email" className="form-control" placeholder="Correo Electrónico" required />
										</div>
										<div className="form-group">
											<input type="text" name="subject" className="form-control" placeholder="Asunto" required />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<textarea name="message" className="form-control" rows="6" placeholder="Mensaje" required></textarea>
										</div>
									</div>
									<div className="col-lg-12 text-center">
										<button type="submit" className="genric-btn primary circle">Enviar Mensaje</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<footer className="footer-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h6>Acerca de Seguros Sadek-Parés</h6>
								<p>
									Ofrecemos soluciones de seguros personalizadas para proteger lo que más valoras. Contáctanos para más información sobre nuestros productos y servicios.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h6>Enlaces Rápidos</h6>
								<ul className="footer-nav">
									<li><a href="/">Inicio</a></li>
									<li><a href="/">Seguros</a></li>
									<li><a href="/">Servicios</a></li>
									<li><a href="/">Contacto</a></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h6>Redes Sociales</h6>
								<p>
									Síguenos en nuestras redes sociales para estar al tanto de nuestras últimas novedades y ofertas.
								</p>
								<div className="footer-social d-flex align-items-center">
									<a href="/"><i className="fa fa-facebook"></i></a>
									<a href="/"><i className="fa fa-twitter"></i></a>
									<a href="/"><i className="fa fa-instagram"></i></a>
									<a href="/"><i className="fa fa-linkedin"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-footer-widget">
								<h6>Suscríbete</h6>
								<p>
									Recibe las últimas noticias y actualizaciones de Seguros Sadek-Parés.
								</p>
								<div className="d-flex flex-row">
									<input className="form-control" placeholder="Correo Electrónico" />
									<button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom d-flex justify-content-between align-items-center flex-wrap">
						<p className="footer-text">
							&copy; 2024 Todos los derechos reservados | Seguros Sadek-Parés
						</p>
					</div>
				</div>
			</footer>
		</div>
		<CookieBanner />
	</div>
  );
}

export default Home;
